<template>
  <div class="feedback">
    <Header />
    <img class="logo" src="@/assets/images/login/logo.png" />
    <div class="desc">感谢您对我们的支持与关注，我们将竭诚为您提供最详实、准确的信息。数据信息是否有误，配图正确与否，都希望得到您客观的评价，虚心接受您最诚挚的意见和建议。</div>
    <Radio class="radio-container" :list="list" v-model="radioValue" />
    <TextArea class="textarea" v-model="inputValue" />
    <Button class="submit-button" size="large" @click="handleSubmit">提交</Button>
  </div>
</template>
<script>
import Header from '@/components/header';
import Radio from '@/components/select/radio';
import TextArea from '@/components/common/textArea';
import Button from '@/components/common/button/CustomButton';
import { submitFeedback } from '@/api/common';
export default {
  components: {
    Radio,
    Header,
    Button,
    TextArea
  },
  data() {
    return {
      radioValue: '1',
      inputValue: '',
      list: [
        {
          key: '1',
          value: '内容意见'
        },
        {
          key: '2',
          value: '产品建议'
        },
        {
          key: '3',
          value: '技术问题'
        },
        {
          key: '4',
          value: '其他'
        }
      ]
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.inputValue) {
        return this.$toast('请输入反馈意见');
      }
      await submitFeedback({ type: this.radioValue, context: this.inputValue });
      this.$toast({
        message: '反馈成功',
        onClose: () => {
          this.inputValue = '';
          this.radioValue = '1';
          this.$router.back();
        }
      });
    },  
  },
}
</script>
<style lang="scss" scoped>
.feedback {
  min-height: 100vh;
  background-color: #FAFBFC;
  .logo {
    height: 71px;
    margin-top: 21px;
  }
  .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #818181;
    margin: 8px auto 0;
    width: 325px;
  }
  .radio-container {
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    ::v-deep .radio-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      box-sizing: border-box;
      .van-radio__icon {
        margin-left: 0;
        width: 15px;
        height: 15px;
      }
      .van-icon {
        font-size: 12px;
        width: 15px;
        height: 15px;
      }
      .van-radio__label {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .submit-button {
    width: 351px;
    border-radius: 10px;
    margin-top: 45px;
    font-weight: bold;
  }
  .textarea {
    margin-top: 4px;
  }
}

</style>